import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { DateFilter } from "src/common/date-filter";

export enum CustomerListStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PENDING = "pending",
}

export const customerListStatusToString = (status: CustomerListStatus) => {
  switch (status) {
    case CustomerListStatus.ACTIVE:
      return i18n._(
        t({
          id: "customers.active",
          message: "Active",
        })
      );
    case CustomerListStatus.INACTIVE:
      return i18n._(
        t({
          id: "customers.inactive",
          message: "Inactive",
        })
      );
    case CustomerListStatus.PENDING:
      return i18n._(
        t({
          id: "customers.pending",
          message: "Pending",
        })
      );
  }
};

export const stringToCustomerListStatus: Map<string, CustomerListStatus> =
  new Map([
    ["pending", CustomerListStatus.PENDING],
    ["inactive", CustomerListStatus.INACTIVE],
    ["active", CustomerListStatus.ACTIVE],
  ]);

export const stringToDateRange: Map<string, DateFilter> = new Map([
  ["anytime", DateFilter.Anytime],
  ["today", DateFilter.Today],
  ["yesterday", DateFilter.Yesterday],
  ["this_week", DateFilter.ThisWeek],
  ["last_week", DateFilter.LastWeek],
  ["this_month", DateFilter.ThisMonth],
  ["last_month", DateFilter.LastMonth],
  ["last_7_days", DateFilter.SevenDays],
  ["last_30_days", DateFilter.ThirtyDays],
  ["custom", DateFilter.Custom],
]);
