import {
  AlignItems,
  Box,
  ButtonGroup,
  ButtonVariant,
  Color,
  ColorPreset,
  Glyph,
  Icon,
  JustifyContent,
  P,
  Space,
  TypePreset,
  ButtonSize,
  Button,
  PlainLink,
} from "@gocardless/flux-react";
import { useUser } from "src/queries/user";
import { Plural, Trans } from "@lingui/macro";
import { Link, Route } from "src/components/routing";
import { useUserEnforcePasswordReset } from "@gocardless/api/dashboard/user";
import { forwardRef } from "react";

export const ForcedPasswordResetBanner = forwardRef<HTMLDivElement, {}>(
  (_props, ref) => {
    const { data: enforcedPasswordResetData } = useUserEnforcePasswordReset();
    const showForcedPasswordResetBanner =
      enforcedPasswordResetData?.remaining_days_to_reset_password &&
      enforcedPasswordResetData.remaining_days_to_reset_password > 0;

    const user = useUser();

    if (!user?.email) {
      return null;
    }
    if (!showForcedPasswordResetBanner) {
      return null;
    }
    const daysUntilRequiredPasswordReset =
      enforcedPasswordResetData.remaining_days_to_reset_password || 0;
    const numberOfDays = (
      <Plural
        id="pluralized-day"
        value={daysUntilRequiredPasswordReset}
        one="# day"
        other="# days"
      />
    );

    const heading = (
      <P preset={TypePreset.Heading_02}>
        <Trans id="force-reset-banner.heading">
          You have {numberOfDays} to update your password
        </Trans>
      </P>
    );
    const description = (
      <P color={ColorPreset.TextOnLight_01} preset={TypePreset.Body_01}>
        <Trans>
          To keep your account secure, we require you to update your password
          within the next {numberOfDays}. You can do this via the link below.
        </Trans>
      </P>
    );

    return (
      <Box
        ref={ref}
        gutterH={1.5}
        gutterV={1.5}
        borderRadius={1}
        layout="flex"
        flexWrap={["wrap", null, "nowrap"]}
        alignItems={AlignItems.Start}
        justifyContent={JustifyContent.Start}
        bg={Color.Sunrise_100}
      >
        <Box
          layout="flex"
          gutterH={0.5}
          gutterV={0.25}
          alignItems={AlignItems.Center}
        >
          <Icon name={Glyph.Warning} size="16px" />
        </Box>
        <Space layout="inline" h={1} />
        <Box layout="flex" flexDirection="column">
          {heading}
          <Space layout="inline" v={0.5} />
          {description}
          <Space layout="inline" v={1} />
          <Box width={["100%", null, "initial"]} flexShrink={[1, null, 0]}>
            <ButtonGroup arrangement={["row-start"]}>
              <Link
                route={Route.Settings}
                anchorId="password-section"
                variant={ButtonVariant.PrimaryAuto}
                size={ButtonSize.Sm}
              >
                <Trans>Update password</Trans>
              </Link>
              <PlainLink href="https://hub.gocardless.com/s/article/Mandatory-Password-Resets">
                <Button variant={ButtonVariant.TextAuto} size={ButtonSize.Sm}>
                  <Trans id="find-out-more">Find out more</Trans>
                </Button>
              </PlainLink>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
    );
  }
);
