import { Box } from "@gocardless/flux-react";
import { ReactElement, forwardRef, useEffect, useState } from "react";
import { useLocalStorage } from "src/hooks/useLocalStorage/useLocalStorage";
import { isDisplayed } from "src/common/isInDom";
import { ContentName } from "src/common/constants/bannerNames";

export { ContentName };

interface DismissibleContentProps {
  name: ContentName;
  renderContent: (dismissContent: () => void) => ReactElement;
  onContentMount?: () => void;
}

export const DismissibleContent = forwardRef<
  HTMLDivElement,
  DismissibleContentProps
>(({ name, renderContent, onContentMount }, ref) => {
  const isRefDefined = !!ref;
  //@ts-expect-error forwardRef is an union of the callback ref and ref objet, hence the error
  const nodeElement = ref?.current as HTMLElement;

  const [contentDismissed, setContentDismissed] = useLocalStorage(
    name,
    String(false)
  );
  const [showContent, setShowContent] = useState(
    !(contentDismissed === "true")
  );
  const dismissContent = () => {
    setShowContent(false);
    setContentDismissed("true");
  };

  useEffect(() => {
    if (showContent && (!isRefDefined || isDisplayed(nodeElement))) {
      onContentMount?.(); // fire onContentMount, provided it is being displayed (not hidden by the TopBanner component)
    }
  }, [showContent, onContentMount, isRefDefined, nodeElement]);

  return showContent ? (
    <Box ref={ref}>{renderContent(dismissContent)}</Box>
  ) : null;
});
